import { useEffect } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import {
  logout,
  ReportTestRankTargetSummary,
  SchoolCategory,
  useReportTestRankTargetSummaryLazyQuery,
} from '@alpha/core'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { prefectureCode } from '../../utils/constant'

type ReportTestRankTargetByCategory = {
  schoolCategory: SchoolCategory
  rates: number[]
}

export type ReportTestRankTarget = {
  year: number
  totalRates: number[]
  schoolCategories: ReportTestRankTargetByCategory[]
}

const styleForReport = {
  fontSize: 14,
  width: 64,
}

const TableHeader = () => {
  return (
    <thead>
      <tr className="table-border-yellow">
        <th className="table-border-yellow" rowSpan={2} style={styleForReport}>
          年度
        </th>
        <th className="table-border-yellow" rowSpan={2} style={styleForReport}>
          全体
        </th>
        <th className="table-border-yellow" colSpan={3}>
          A+Bの割合
        </th>
        <th className="table-border-yellow" colSpan={3}>
          D+Eの割合
        </th>
      </tr>
      <tr className="table-border-yellow">
        <th className="table-border-yellow" style={styleForReport}>
          小学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          中学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          高校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          小学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          中学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          高校
        </th>
      </tr>
    </thead>
  )
}

// const makeYearName = (y: number): string => {
//   if (y >= 2019) {
//     return `R${y - 2018}`
//   }
//   return `H${y - 1988}`
// }

type TableBodyProps = {
  data: ReportTestRankTargetSummary
}

const fixedRates = {
  R5: [48.8, 46.5, 53.6, 49.7, 24.5, 20.6, 19.1],
  R4: [47.5, 44.3, 52.4, 49.8, 25.7, 21.1, 19.2],
  R3: [48.2, 44.8, 52.8, 51.3, 25.8, 21.5, 18.1],
  R1: [54.8, 52.8, 59.5, 54.4, 19.5, 16.1, 16.0],
  H30: [57.3, 56.0, 62.2, 54.4, 17.3, 14.4, 15.6],
  H29: [57.0, 55.9, 61.9, 53.6, 17.3, 14.5, 16.6],
  H28: [56.1, 54.5, 60.7, 54.4, 18.8, 14.9, 16.0],
  H27: [55.1, 53.0, 60.1, 54.1, 18.8, 15.2, 15.7],
  H26: [55.0, 53.3, 59.6, 53.6, 18.4, 15.4, 16.1],
  H25: [54.0, 51.9, 60.6, 51.0, 19.2, 14.6, 17.8],
  H24: [53.9, 51.7, 60.4, 51.2, 18.9, 14.5, 17.0],
  H23: [51.3, 49.3, 57.4, 49.2, 20.6, 15.8, 18.8],
  H22: [51.4, 49.3, 57.5, 49.0, 20.3, 15.8, 18.5],
}

const TableBody = ({ data }: TableBodyProps) => {
  let reports = data.reports?.slice().sort((a, b) => b.year! - a.year!)

  if (!reports) {
    return null
  }

  const rates: { [key: string]: any } = fixedRates

  return (
    <tbody>
      {Object.entries(rates).map(([key, data]) => {
        return (
          <tr className="table-border-yellow" key={key}>
            <th className="table-border-yellow">{key}</th>
            <td className="table-border-yellow">{data[0].toFixed(1)}%</td>

            <td className="table-border-yellow">{data[1].toFixed(1)}%</td>
            <td className="table-border-yellow">{data[2].toFixed(1)}%</td>
            <td className="table-border-yellow">{data[3].toFixed(1)}%</td>
            <td className="table-border-yellow">{data[4].toFixed(1)}%</td>
            <td className="table-border-yellow">{data[5].toFixed(1)}%</td>
            <td className="table-border-yellow">{data[6].toFixed(1)}%</td>
          </tr>
        )
      })}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
}

const ReportTestRankTargetTable = ({ prefectureCode }: TableProps) => {
  const [getReport, { data, loading, error }] =
    useReportTestRankTargetSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (error) {
      const errorMessage = JSON.stringify(error?.networkError)
      if (errorMessage.includes('"code":"UNAUTHENTICATED"')) {
        logout()
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    }
  }, [error])
  useEffect(() => {
    if (prefectureCode === 0) return
    getReport({
      variables: {
        input: {
          prefectureCode,
        },
      },
    })
  }, [prefectureCode, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportTestRankTargetSummary?.data) {
    return <NoData />
  }

  let reportData = data.reportTestRankTargetSummary.data

  return (
    <>
      <table className="table border-collapse">
        <TableHeader />
        <TableBody data={reportData} />
      </table>
    </>
  )
}

const ReportTestRankTargetPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数の割合の推移</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div className="pt-4 pb-2">
            <span className="border border-black">目標水準</span>
            「体力テストにおける総合評価Ａ及びＢの割合」
            <br />
            平成２７年度 55.0％ ⇒
            <span className="border border-black">60.0％</span>
            （令和２年度目標値）
          </div>

          <div className="font-bold">
            ＜平成21年度からの（Ａ＋Ｂの割合）及び（Ｄ＋Ｅの割合）の推移＞
          </div>

          <ReportTestRankTargetTable prefectureCode={prefectureCode} />
        </Card>
      </div>
      <div className="hidden print:block">
        <div>茨城県児童生徒の体力・運動能力調査結果</div>
        <div>総合評価段階別人数の割合の推移</div>

        <div className="pt-4 pb-2">
          <span className="border border-black">目標水準</span>
          「体力テストにおける総合評価Ａ及びＢの割合」
          <br />
          平成２７年度 55.0％ ⇒
          <span className="border border-black">60.0％</span>
          （令和２年度目標値）
        </div>

        <div className="font-bold">
          ＜平成21年度からの（Ａ＋Ｂの割合）及び（Ｄ＋Ｅの割合）の推移＞
        </div>

        <ReportTestRankTargetTable prefectureCode={prefectureCode} />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankTargetPage
