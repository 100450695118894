import { useEffect, useState } from 'react'
import {
  logout,
  ReportCountByCategory,
  ReportCountByGrade,
  useReportCountSummaryLazyQuery,
} from '@alpha/core'
import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import { Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { count2023 } from './data/count'
import { defaultSchoolYear, prefectureCode } from '../../utils/constant'

const { Option } = Select

const ReportTableData = ({ data }: { data: ReportCountByGrade }) => {
  if (!data) return null

  let totalTotalCount = 0,
    totalSamplingCount = 0
  const formatter = new Intl.NumberFormat('ja-JP')
  const genderReports = [data.male, data.female].map((d) => {
    if (!d) return null
    const { totalCount, samplingCount } = d
    if (!totalCount || !samplingCount) return null
    totalTotalCount += totalCount
    totalSamplingCount += samplingCount
    return (
      <>
        <td className="table-border-yellow">{formatter.format(totalCount)}</td>
        <td className="table-border-yellow">
          {formatter.format(samplingCount)}
        </td>
        <td className="table-border-yellow">
          {((samplingCount / totalCount) * 100).toFixed(1)}%
        </td>
      </>
    )
  })
  return (
    <tr className="table-border-yellow">
      <td className="table-border-yellow">
        {data.grade !== 0 ? `${data.grade}学年` : ''}
      </td>
      {genderReports}
      <td className="table-border-yellow">
        {formatter.format(totalTotalCount)}
      </td>
      <td className="table-border-yellow">
        {formatter.format(totalSamplingCount)}
      </td>
      <td className="table-border-yellow">
        {((totalSamplingCount / totalTotalCount) * 100).toFixed(1)}%
      </td>
    </tr>
  )
}

const schoolKeys = [
  'elementarySchoolReport',
  'juniorHighSchoolReport',
  'allDayHighSchoolReport',
  'fixTimeHighSchoolReport',
]
const genders = ['male', 'female']

const ReportCountTable = ({
  prefectureCode,
  year,
}: {
  prefectureCode: number
  year: number
}) => {
  const [getReportCount, { data: reportCountData, called, loading, error }] =
    useReportCountSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (error) {
      const errorMessage = JSON.stringify(error?.networkError)
      if (errorMessage.includes('"code":"UNAUTHENTICATED"')) {
        logout()
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    }
  }, [error])

  const [totalReport, setTotalReport] = useState<ReportCountByCategory>({
    schoolCount: 0,
    reports: [],
  })

  useEffect(() => {
    if (prefectureCode === 0) return

    const variables = {
      input: {
        prefectureCode,
        year,
      },
    }

    if (year !== 2023) {
      getReportCount({ variables })
    }
    // else do not call API, use data from FE
  }, [getReportCount, prefectureCode, year])

  useEffect(() => {
    let data: typeof reportCountData.reportCountSummary.data | typeof count2023

    if (year !== 2023) {
      data = reportCountData?.reportCountSummary?.data
    } else {
      data = count2023
    }

    if (!data) return
    const totalReport: ReportCountByGrade = {
      grade: 0,
      male: {
        totalCount: 0,
        samplingCount: 0,
      },
      female: {
        totalCount: 0,
        samplingCount: 0,
      },
    }
    const totalData: ReportCountByCategory = {
      schoolCount: 0,
      reports: [totalReport],
    }
    schoolKeys.forEach((key) => {
      const d = data[key]
      if (!d) return
      totalData.schoolCount += d.schoolCount
      genders.forEach((gender) => {
        d.reports.forEach((report) => {
          const r = report[gender]
          totalReport[gender].totalCount += r.totalCount
          totalReport[gender].samplingCount += r.samplingCount
        })
      })
    })
    setTotalReport(totalData)
  }, [reportCountData, year])

  if (loading) {
    return <Loading />
  }

  if (
    (!reportCountData?.reportCountSummary ||
      !reportCountData.reportCountSummary.data) &&
    year !== 2023
  ) {
    if (called) {
      return <NoData />
    } else {
      return null
    }
  }

  let reportData:
    | typeof reportCountData.reportCountSummary.data
    | typeof count2023

  if (year !== 2023) {
    reportData = reportCountData?.reportCountSummary?.data
  } else {
    reportData = count2023
  }

  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  } = reportData

  return (
    <table className="table border-collapse">
      <thead>
        <tr className="border border-white">
          <th rowSpan={2} className="table-border-yellow">
            校種
          </th>
          <th rowSpan={2} className="table-border-yellow">
            校数
          </th>
          <th rowSpan={2} className="table-border-yellow">
            学年
          </th>
          <th colSpan={3} className="table-border-yellow">
            男子
          </th>
          <th colSpan={3} className="table-border-yellow">
            女子
          </th>
          <th colSpan={3} className="table-border-yellow">
            合計
          </th>
        </tr>
        <tr className="border border-white">
          <th className="table-border-yellow">在籍人数</th>
          <th className="table-border-yellow">実施人数</th>
          <th className="table-border-yellow">実施率</th>
          <th className="table-border-yellow">在籍人数</th>
          <th className="table-border-yellow">実施人数</th>
          <th className="table-border-yellow">実施率</th>
          <th className="table-border-yellow">在籍人数</th>
          <th className="table-border-yellow">実施人数</th>
          <th className="table-border-yellow">実施率</th>
        </tr>
      </thead>
      <tbody>
        <tr className="table-border-yellow">
          <th rowSpan={7} className="table-border-yellow">
            小学校
          </th>
          <td rowSpan={7} className="table-border-yellow">
            {elementarySchoolReport?.schoolCount}
          </td>
        </tr>
        {elementarySchoolReport?.reports?.map((data) => (
          <ReportTableData data={data} />
        ))}
        <tr className="table-border-yellow">
          <th rowSpan={4} className="table-border-yellow">
            中学校
          </th>
          <td rowSpan={4} className="table-border-yellow">
            {juniorHighSchoolReport?.schoolCount}
          </td>
        </tr>
        {juniorHighSchoolReport?.reports?.map((data) => (
          <ReportTableData data={data} />
        ))}
        <tr className="table-border-yellow">
          <th rowSpan={4} className="table-border-yellow">
            高等学校
          </th>
          <td rowSpan={4} className="table-border-yellow">
            {allDayHighSchoolReport?.schoolCount}
          </td>
        </tr>
        {allDayHighSchoolReport?.reports?.map((data) => (
          <ReportTableData data={data} />
        ))}
        <tr className="table-border-yellow">
          <th rowSpan={5} className="table-border-yellow">
            高等学校
            <br />
            (定時制)
          </th>
          <td rowSpan={5} className="table-border-yellow">
            {fixTimeHighSchoolReport?.schoolCount}
          </td>
        </tr>
        {fixTimeHighSchoolReport?.reports?.map((data) => (
          <ReportTableData data={data} />
        ))}
        <tr className="table-border-yellow">
          <th rowSpan={2} className="table-border-yellow">
            合計
          </th>
          <td rowSpan={2} className="table-border-yellow">
            {totalReport?.schoolCount}
          </td>
        </tr>
        {totalReport?.reports?.map((data) => (
          <ReportTableData data={data} />
        ))}
      </tbody>
    </table>
  )
}

const ReportCountPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [year, setYear] = useState(defaultSchoolYear)

  return (
    <Dashboard
      navbar={<div className="theme-nav-title">調査人数</div>}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div className="space-y-4 pb-8">
            <Select value={year} onChange={(v) => setYear(v)}>
              <Option value={2012}>2012 ({makeYearName(2012)})</Option>
              <Option value={2013}>2013 ({makeYearName(2013)})</Option>
              <Option value={2014}>2014 ({makeYearName(2014)})</Option>
              <Option value={2015}>2015 ({makeYearName(2015)})</Option>
              <Option value={2016}>2016 ({makeYearName(2016)})</Option>
              <Option value={2017}>2017 ({makeYearName(2017)})</Option>
              <Option value={2018}>2018 ({makeYearName(2018)})</Option>
              <Option value={2019}>2019 ({makeYearName(2019)})</Option>
              <Option value={2021}>2021 ({makeYearName(2021)})</Option>
              <Option value={2022}>2022 ({makeYearName(2022)})</Option>
              <Option value={2023}>2023 ({makeYearName(2023)})</Option>
              <Option value={2024}>2024 ({makeYearName(2024)})</Option>
            </Select>
          </div>

          <ReportCountTable prefectureCode={prefectureCode} year={year} />
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県児童生徒の体力・運動能力調査結果
        </div>
        <div>調査人数</div>
        <ReportCountTable prefectureCode={prefectureCode} year={year} />
      </div>
    </Dashboard>
  )
}

export default ReportCountPage
