import { gradeNumberWithoutFixTimeHighSchool } from '../../../../utils/constant'
import {
  AllSchoolEachQuestionRateFromExcel,
  KeyValue,
  QuestionsValue,
} from './type'
import { reportQuestionnaireRate2023 } from './year2023'

export const transformKeyValueObjectsToOrderedArray = (
  kvObjArr: { k: number; v: number }[],
): number[] => {
  const res: number[] = []
  /**
   * let currentKey = 1;
   * 1, 2
   * or
   * 1, 2, 3, 4,
   */
  for (let currentKey = 1; currentKey <= kvObjArr.length; currentKey++) {
    for (const kvObj of kvObjArr) {
      if (kvObj.k === currentKey) {
        res.push(kvObj.v)
      }
    }
  }

  return res
}

type SchoolQuestionsReports = {
  reports: {
    grade: number
    countByAnswer: QuestionsValue
  }[]
}

type AllSchoolsQuestionsReport = {
  elementarySchoolReport: SchoolQuestionsReports
  juniorHighSchoolReport: SchoolQuestionsReports
  highSchoolReport: SchoolQuestionsReports
}

export const transformFromExcelDataEachYearToQuestionReport = (
  isMale: boolean,
): AllSchoolsQuestionsReport => {
  const rawData = reportQuestionnaireRate2023
  const questionStartId = isMale ? 0 : 12

  const elementarySchoolReport = { reports: [] } as SchoolQuestionsReports
  const juniorHighSchoolReport = { reports: [] } as SchoolQuestionsReports
  const highSchoolReport = { reports: [] } as SchoolQuestionsReports

  for (let i = 0; i < gradeNumberWithoutFixTimeHighSchool; i++) {
    let graphDataForSchool: SchoolQuestionsReports

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else {
      graphDataForSchool = highSchoolReport
      grade = i - 8
    }

    const countByAnswer = {} as QuestionsValue
    const eachGradeData: SchoolQuestionsReports['reports'][0] = {
      grade,
      countByAnswer,
    }
    for (let questionId = 2; questionId <= 7; questionId++) {
      /**
       * @example `q1`
       */
      const questionName = `q${questionId}`
      // `{ q1: [] }`
      countByAnswer[questionName] = []

      /**
       * @example `[{k: 1, v: 46.2}, {k: 2, v: 53.8}]`
       */
      const resQuestionKeyValues: KeyValue[] = countByAnswer[questionName]

      // get data from storage for a question: start
      const storageQuestionScore: AllSchoolEachQuestionRateFromExcel =
        rawData[questionName]

      /**
       * Start from 0 if Male, otherwise start from 12.
       */
      const gradeIdInStorage = i + questionStartId
      const answerArr = storageQuestionScore[gradeIdInStorage]

      for (
        let answerPathId = 0;
        answerPathId < answerArr.length;
        answerPathId++
      ) {
        const value = answerArr[answerPathId]

        const keyValue: KeyValue = {
          k: answerPathId + 1,
          v: value,
        }

        resQuestionKeyValues.push(keyValue)
      }
      // get data from storage for a question: end
    }

    graphDataForSchool.reports.push(eachGradeData)
  }

  return { elementarySchoolReport, juniorHighSchoolReport, highSchoolReport }
}
