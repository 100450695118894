import {
  ReportTestByCategory,
  ReportTestByGrade,
  ReportTestSummary,
} from '@alpha/core'
import { testReportSubjectNames } from '../../../../utils/constant'
import { CalculatorType, TestReportFromExcel } from './type'
import {
  testAverage2023Female,
  testAverage2023Male,
  testDeviation2023Female,
  testDeviation2023Male,
} from './year2023'

export const transformFromExcelDataEachYearTestReport = (
  isMale: boolean,
  type: CalculatorType,
): ReportTestSummary => {
  let rawData: TestReportFromExcel

  // maybe add parameter for the year
  if (isMale && type === CalculatorType.average) {
    rawData = testAverage2023Male
  } else if (!isMale && type === CalculatorType.average) {
    rawData = testAverage2023Female
  } else if (isMale && type === CalculatorType.deviation) {
    rawData = testDeviation2023Male
  } else {
    rawData = testDeviation2023Female
  }

  const elementarySchoolReport = { reports: [] } as ReportTestByCategory
  const juniorHighSchoolReport = { reports: [] } as ReportTestByCategory
  const allDayHighSchoolReport = { reports: [] } as ReportTestByCategory
  const fixTimeHighSchoolReport = { reports: [] } as ReportTestByCategory

  for (let i = 0; i < rawData.length; i++) {
    const gradeScore = rawData[i]

    let graphDataForSchool: ReportTestByCategory

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else if (9 <= i && i <= 11) {
      graphDataForSchool = allDayHighSchoolReport
      grade = i - 8
    } else {
      graphDataForSchool = fixTimeHighSchoolReport
      grade = i - 11
    }

    const report = { grade, [type]: {} } as ReportTestByGrade
    const reportType = report[type]
    const reports = graphDataForSchool.reports

    for (let j = 0; j < gradeScore.length; j++) {
      const subjectScore = gradeScore[j]
      const subjectName = testReportSubjectNames[j]
      reportType[subjectName] = subjectScore
    }

    reports.push(report)
  }

  return {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  }
}
