import { SignInLayout } from '@alpha/components'
import { signIn } from '@alpha/core'
import { Divider, message } from 'antd'
import { useCallback, useState } from 'react'

import { EmailSent } from '../shared/EmailSent'

const SignInPage = () => {
  const [address, setAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const handleSignInBtn = useCallback(async () => {
    if (
      [
        'hotai2@pref.ibaraki.lg.jp',
        'inoue@pestalozzi-tech.com',
        'sergei@pestalozzi-tech.com',
        'phamvantien142@gmail.com',
        'tranvansangk41@gmail.com',
        'me@transa.ng',
        'locluuvinhbk@gmail.com',
      ].includes(address) === false
    ) {
      message.warning(
        '本メールアドレスが登録されていません。管理者にお問い合わせください。',
      )
    } else {
      setIsLoading(true)
      const { protocol, host } = window.location
      const url = `${protocol}//${host}/`
      await signIn(address, url)
      setIsSent(true)
    }
  }, [address])

  return (
    <SignInLayout>
      <div className="flex flex-col space-y-2">
        {isSent ? (
          <div className="flex flex-col items-center space-y-12">
            <EmailSent />
            <div className="text-center">
              <h1 className="text-xl font-semibold">
                ログインメールを送信しました
              </h1>
              <h5 className="text-gray-400 font-bold">
                ※ メールに送信したリンクをこちらのブラウザーで開いてください。
              </h5>
            </div>
          </div>
        ) : (
          <>
            <h5 className="font-bold text-2xl">
              茨城県児童生徒の体力・運動能力調査
              <br />
              集計システム「Alpha」へようこそ
            </h5>
            <p>メールアドレス</p>
            <input
              type="email"
              placeholder="メールアドレス"
              className="bg-gray-50 p-2 rounded border border-gray-200"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <div className="pt-4">
              <button
                className="bg-blue-500 hover:bg-blue-400 focus:bg-blue-400　text-white font-semibold py-2 p px-4 mb-4 rounded"
                onClick={handleSignInBtn}
                disabled={isLoading}
                style={{ color: 'white' }}
              >
                メールにサインイン情報を送る
              </button>
            </div>
            <div className="flex">
              <div className="text-red-500 p-2 border-red-500 border mb-4">
                HTML形式でメールが届きます。HTML形式メールを許可してください。
                <br />
                設定方法：
                <a
                  className="underline font-semibold"
                  href="https://www.crie.co.jp/chokotech/detail/180/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.crie.co.jp/chokotech/detail/180/
                </a>
                <br />
                <small>*外部リンクとなります</small>
              </div>
            </div>
            <Divider />
            <div className="pt-4">
              <p>
                続行することで、Pestalozzi Technologyの
                <a
                  className="text-blue-700 underline font-semibold"
                  href="https://pestalozzi-tech.com/alpha-terms/"
                >
                  サービス利用規約
                </a>
                および、
                <a
                  className="text-blue-700 underline font-semibold"
                  href="https://pestalozzi-tech.com/privacy-policy/"
                >
                  個人情報保護ポリシー
                </a>
                に同意します。
              </p>
            </div>
          </>
        )}
      </div>
    </SignInLayout>
  )
}

export default SignInPage
