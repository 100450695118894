import { SchoolCategory, UserGender } from '@alpha/core'

/**
 * Key Order is importance!
 * Total is 11 keywords
 */
export const tScoreSubjectNames = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
  'totalScore',
  'height',
  'weight',
] as const

/**
 * Key Order is importance!
 * Total is 9 keywords
 */
export const testReportSubjectNames = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
  'totalScore',
] as const

/**
 * Ibaraki prefecture
 */
export const prefectureCode = 8

export const schoolsReportType = [
  'elementarySchoolReport',
  'juniorHighSchoolReport',
  'allDayHighSchoolReport',
  'fixTimeHighSchoolReport',
] as const

export const schoolCategoriesType3 = [
  SchoolCategory.ElementarySchool,
  SchoolCategory.JuniorHighSchool,
  SchoolCategory.HighSchool,
] as const

export const schoolCategoriesType3Obj = {
  elementarySchoolReport: SchoolCategory.ElementarySchool,
  juniorHighSchoolReport: SchoolCategory.JuniorHighSchool,
  highSchoolReport: SchoolCategory.HighSchool,
} as const

export const genderTexts = [UserGender.Male, UserGender.Female]

export enum SchoolLevelNoFixTime {
  element = 'ELEMENTARY_SCHOOL',
  junior = 'JUNIOR_HIGH_SCHOOL',
  high = 'ALL_DAY_HIGH_SCHOOL',
}

/**
 * 12 = 6 (ELEMENTARY_SCHOOL) + 3 (JUNIOR_HIGH_SCHOOL) + 3 (ALL_DAY_HIGH_SCHOOL)
 */
export const gradeNumberWithoutFixTimeHighSchool = 12

export const defaultSchoolYear = 2024
