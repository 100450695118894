/**
 * Copy from API response (for 2022 and the past).
 * Added data from 2023 data to this.
 */
export const testRankTargetGraphData = {
  reportTestRankTargetSummary: {
    data: {
      reports: [
        {
          year: 2012,
          totalRates: [
            23.47931043888601, 30.445500668285096, 28.632606857423422,
            13.288303646927252, 4.154278388478219,
          ],
          elementarySchoolRates: [
            21.32808564231738, 30.391057934508815, 29.2764483627204,
            13.892947103274558, 5.111460957178841,
          ],
          juniorHighSchoolRates: [
            29.512628408125437, 30.945392755078398, 25.019953142298085,
            11.390026003449963, 3.131999691048119,
          ],
          highSchoolRates: [
            21.242474386508466, 29.91409358166391, 31.773052142379328,
            14.193923177129175, 2.876456712319121,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2013,
          totalRates: [
            23.5902408616301, 30.396010458693357, 28.28002978303406,
            13.488251285692025, 4.24546761095046,
          ],
          elementarySchoolRates: [
            21.36007477018411, 30.466289401582465, 28.904949683763093,
            14.00788671804983, 5.260799426420506,
          ],
          juniorHighSchoolRates: [
            29.66352061491804, 30.898847028680485, 24.82810008104787,
            11.440821982274047, 3.1687102930795574,
          ],
          highSchoolRates: [
            21.516638415558926, 29.513783566776695, 31.249888482469444,
            14.834507984655188, 2.8851815505397447,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2014,
          totalRates: [
            24.33550449774587, 30.703162795263168, 27.807497879083204,
            13.052227137919187, 4.101607689988572,
          ],
          elementarySchoolRates: [
            22.361625541238457, 30.914497365538107, 28.285252230163284,
            13.493139965569409, 4.94548489749074,
          ],
          juniorHighSchoolRates: [
            29.366474291710386, 30.25445960125918, 25.010493179433368,
            11.937303252885624, 3.4312696747114373,
          ],
          highSchoolRates: [
            22.882756638281055, 30.735510761381196, 30.322302468470415,
            13.364593438970932, 2.6948366928964105,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2015,
          totalRates: [
            24.630296297087593, 30.484665263697643, 27.678015048089076,
            13.015920493674177, 4.19110289745151,
          ],
          elementarySchoolRates: [
            22.47275186295049, 30.51923943886075, 28.273091304463126,
            13.538545174891937, 5.1963722188337,
          ],
          juniorHighSchoolRates: [
            29.82296867907399, 30.322287789378123, 24.690929481188753,
            11.808763450909188, 3.3550505994499478,
          ],
          highSchoolRates: [
            23.477629548960884, 30.610763227153097, 30.10981032761594,
            13.226245575823578, 2.5755513204465017,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2016,
          totalRates: [
            25.82559892722341, 30.529206955349757, 27.197541326744197,
            12.447203531449848, 4.000449259232788,
          ],
          elementarySchoolRates: [
            23.338905752789877, 30.237707682606718, 27.83423518542959,
            13.373806695701903, 5.21534468347191,
          ],
          juniorHighSchoolRates: [
            27.993573386258387, 31.682125642998905, 25.18395506770897,
            11.701567499696221, 3.438778403337519,
          ],
          highSchoolRates: [
            27.96770788349887, 29.981766709850778, 27.944916270812342,
            11.593733506069766, 2.5118756297682454,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2017,
          totalRates: [
            26.600715285109427, 30.41934411497167, 26.589390546400377,
            12.399127629805252, 3.991422423713272,
          ],
          elementarySchoolRates: [
            25.26970445029391, 30.642653537185797, 26.764501302491784,
            12.52642489873297, 4.796715811295536,
          ],
          juniorHighSchoolRates: [
            31.839763022022893, 30.03059119914732, 23.599518292429718,
            11.292443558545465, 3.2376839278546017,
          ],
          highSchoolRates: [
            23.258951360023335, 30.334354262378767, 30.059068037628528,
            13.516371326478524, 2.831255013490848,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2018,
          totalRates: [
            27.003439221922264, 30.31840538441626, 26.43948078843238,
            12.212935912133426, 4.025738693095669,
          ],
          elementarySchoolRates: [
            25.528171434069634, 30.514357190966447, 26.62712540033814,
            12.516666437574742, 4.813679537051037,
          ],
          juniorHighSchoolRates: [
            32.4732695554305, 29.66657287563309, 23.428531232414183,
            11.128306133933597, 3.3033202025886323,
          ],
          highSchoolRates: [
            23.768299026528943, 30.64947610908821, 29.908226201976667,
            12.824180723786876, 2.8498179386193057,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2019,
          totalRates: [
            25.039020814898926, 29.791395293197276, 27.251909266636538,
            13.380626687579039, 4.537047937688222,
          ],
          elementarySchoolRates: [
            23.05317805528273, 29.73410972358636, 27.73396941209485,
            13.93222954960011, 5.546513259435947,
          ],
          juniorHighSchoolRates: [
            30.37069597069597, 29.07985347985348, 24.43076923076923,
            12.372161172161173, 3.7465201465201465,
          ],
          highSchoolRates: [
            23.500409282138165, 30.87129504483067, 29.609183148997737,
            13.194113951761816, 2.824998572271611,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2021,
          totalRates: [
            21.14345434365202, 28.43688478769859, 29.076908120194762,
            15.797639205074818, 5.545113543379809,
          ],
          elementarySchoolRates: [
            18.202872047008036, 27.874295479074227, 29.961626094255905,
            17.119109005876005, 6.842097373785825,
          ],
          juniorHighSchoolRates: [
            26.54799962754896, 28.16350600577299, 26.14916664080201,
            14.449548403116175, 4.689779322759862,
          ],
          highSchoolRates: [
            22.043098522569586, 30.328136478654805, 30.51791690474247,
            13.972328789486573, 3.138519304546568,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2022,
          totalRates: [
            19.878479282913894, 27.650843487172814, 29.273153194189128,
            16.66029509850173, 6.537228937222431,
          ],
          elementarySchoolRates: [
            17.37366478782571, 27.004408913640688, 29.941688561526426,
            17.712754410784996, 7.967483326222183,
          ],
          juniorHighSchoolRates: [
            24.67599711300091, 27.71362224244516, 26.561960648947185,
            15.542724448489032, 5.505695547117708,
          ],
          highSchoolRates: [
            20.472846345532055, 29.34695306546945, 30.992441872438576,
            15.231583499804355, 3.9561752167555655,
          ],
          __typename: 'ReportTestRankTargetByYear',
        },

        {
          year: 2023,
          totalRates: [21.29, 27.48, 28.58, 16.38, 6.27],
          elementarySchoolRates: [19.65, 26.86, 28.97, 17.03, 7.5],
          juniorHighSchoolRates: [25.72, 27.85, 25.79, 15.29, 5.35],
          highSchoolRates: [19.93, 28.7, 31.2, 16.08, 4.1],
          __typename: 'ReportTestRankTargetByYear',
        },
      ],
      national: [
        {
          year: 2012,
          totalRates: [
            16.438214285714285, 30.301785714285717, 32.77357142857143,
            16.662857142857142, 3.822857142857143,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2013,
          totalRates: [
            15.138571428571428, 30.430714285714284, 34.033928571428575,
            16.95357142857143, 3.443214285714286,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2014,
          totalRates: [
            17.712142857142858, 31.34357142857143, 32.551428571428566,
            15.591785714285715, 2.8007142857142857,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2015,
          totalRates: [
            17.105, 32.1975, 33.50714285714286, 14.852500000000001,
            2.338214285714286,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2016,
          totalRates: [
            18.91857142857143, 31.06392857142857, 31.657857142857143,
            14.972142857142858, 3.3892857142857147,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2017,
          totalRates: [
            18.194642857142856, 31.648214285714285, 32.622499999999995,
            14.925714285714287, 2.6089285714285713,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2018,
          totalRates: [
            19.662499999999998, 31.95357142857143, 31.4325, 14.419285714285715,
            2.5321428571428575,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
        {
          year: 2019,
          totalRates: [
            18.248571428571427, 31.202142857142857, 32.2425, 15.363928571428572,
            2.942857142857143,
          ],
          elementarySchoolRates: [],
          juniorHighSchoolRates: [],
          highSchoolRates: [],
          __typename: 'ReportTestRankTargetByYear',
        },
      ],
      __typename: 'ReportTestRankTargetSummary',
    },
    error: null,
    __typename: 'ReportTestRankTargetSummaryResponse',
  },
}
