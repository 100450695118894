import {
  ReportAverageScoreGraphByCategory,
  ReportAverageScoreGraphByGrade,
  ReportAverageScoreGraphByYear,
  ReportAverageScoreGraphSummary,
} from '@alpha/core'
import { testAverage2023Female, testAverage2023Male } from '../test-report'
import { testReportSubjectNames } from '../../../../utils/constant'

export const addMoreYearDataToCurrentResponse = (
  response: ReportAverageScoreGraphSummary,
  isMale: boolean,
) => {
  const rawData = isMale ? testAverage2023Male : testAverage2023Female

  const { elementarySchoolReport, juniorHighSchoolReport, highSchoolReport } =
    response

  let lastCreatedObj: ReportAverageScoreGraphByYear
  for (let i = 0; i < rawData.length; i++) {
    const gradeScore = rawData[i]

    let graphDataForSchool: ReportAverageScoreGraphByCategory

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else if (9 <= i && i <= 11) {
      graphDataForSchool = highSchoolReport
      grade = i - 8
    } else {
      break
    }

    let report: ReportAverageScoreGraphByYear
    if (grade === 1) {
      // create new object
      report = { year: 2023, grades: [] }
      // save for later use
      lastCreatedObj = report
      graphDataForSchool.reports.push(report)
    } else {
      // reuse current object
      report = lastCreatedObj
    }

    const resGradeScore = { grade, score: {} } as ReportAverageScoreGraphByGrade
    report.grades.push(resGradeScore)

    const score = resGradeScore.score

    for (let j = 0; j < gradeScore.length; j++) {
      const subjectScore = gradeScore[j]
      const subjectName = testReportSubjectNames[j]
      score[subjectName] = subjectScore
    }
  }

  return response
}
